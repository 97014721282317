import Integration from './Integration';

export default class ImportedIdIntegration extends Integration {
	constructor(integration) {
		super(integration);

		/**
		 * @type {boolean}
		 */
		this.registrationEnabled = false;
		/**
		 * @type {string|null}
		 */
		this.authenticationTitle = integration.authentication_title;
		/**
		 * @type {string|null}
		 */
		this.loginFieldPlaceholder = integration.login_field_placeholder;
		/**
		 * @type {boolean}
		 */
		this.checkForename = integration.check_forename;
		/**
		 * @type {boolean}
		 */
		this.checkSurname = integration.check_surname;
		/**
		 * @type {boolean}
		 */
		this.checkEmail = integration.check_email;
		/**
		 * @type {boolean}
		 */
		this.checkPhone = integration.check_phone;
	}
}
