// example:
import achievement from 'assets/images/examples/achievement.png';
import gamfiLogo from 'assets/images/examples/gamfiLogo.svg';
import removedSubmission from 'assets/images/examples/removedSubmission.png';
//example.icon:
import iconMission from 'assets/images/examples/iconMission.png';
import calendar from 'assets/images/icons/monthly-calendar.svg';
import awardIcon from 'assets/images/icons/award.svg';
//example.icon.challenge
import textIcon from 'assets/images/icons/textTaskIcon.svg';
import videoIcon from 'assets/images/icons/videoTaskIcon.svg';
import imageIcon from 'assets/images/icons/photoTaskIcon.svg';
import challengeIcon from 'assets/images/icons/challengeIcon.svg';
import rankingIcon from 'assets/images/icons/ranking.png';
//example.icon.challenge.mode
import teamIcon from 'assets/images/icons/teamIcon.svg';
import userIcon from 'assets/images/icons/userIcon.svg';
import individualMode from 'assets/images/icons/individual.svg';
import teamMode from 'assets/images/icons/team.svg';
//example.icon.challenge.mode
//welcomePage
import welcomePage1 from 'assets/images/welcomePage/welcome1.png';

// icon:
import gamfi from 'assets/images/icons/gamfi.png';
//icon.staticNotificationTypes
import staticDefaultNotification from 'assets/images/icons/staticDefaultNotification.svg';
import staticEmailNotification from 'assets/images/icons/staticEmailNotification.svg';
import staticBonusTransactionNotification from 'assets/images/icons/bonusTransactionIcon.svg';
//icon.staticNotificationTypes.challengeSubmission
import newStatus from 'assets/images/icons/newStatus.svg';
import acceptedStatus from 'assets/images/icons/acceptedStatus.svg';
import rejectedStatus from 'assets/images/icons/rejectedStatus.svg';
//icon.submissionType
import submissionTypeGif from 'assets/images/icons/submissionType/gif.png';
import submissionTypeImage from 'assets/images/icons/submissionType/image.svg';
import submissionTypeImageWhite from 'assets/images/icons/submissionType/imageWhite.png';
import submissionTypeImage2 from 'assets/images/icons/submissionType/image2.png';
import submissionTypeLink from 'assets/images/icons/submissionType/link.png';
import submissionTypeText from 'assets/images/icons/submissionType/text.svg';
import submissionTypeVideo from 'assets/images/icons/submissionType/video.svg';
import submissionTypeVideoWhite from 'assets/images/icons/submissionType/videoWhite.png';
import submissionTypeVideo2 from 'assets/images/icons/submissionType/video2.png';
import orderTaskIcon from 'assets/images/icons/orderTaskIcon.svg';

// currency:
import bucks from 'assets/images/icons/bucks.png';
import bucksBig from 'assets/images/icons/bucks--big.png';
import diamond from 'assets/images/icons/diamond.png';
import diamondBig from 'assets/images/icons/diamond--big.png';
import exp from 'assets/images/icons/exp.png';
import expBig from 'assets/images/icons/exp--big.png';
import action from 'assets/images/icons/action.png';
import actionBig from 'assets/images/icons/action--big.png';

// official images:
import defaultUserAvatar from 'assets/images/_/user.png';
import defaultTeamAvatar from 'assets/images/_/team.png';

// portal
import { MdAlarm, MdError } from 'react-icons/md';
import { FiCheck, FiChevronDown, FiChevronRight, FiChevronUp, FiFilter, FiMail, FiSmartphone } from 'react-icons/fi';
import users from 'assets/images/icons/users.svg';
import sectionIcons from './sectionIcons';

// battles
import battlesLogo from 'assets/images/battles/battles.png';
import battlesIcon from 'assets/images/battles/battles2.png';
import battlesHallOfFame from 'assets/images/battles/hof.png';
import warning from 'assets/images/battles/icons/warning.svg';

export default {
	default: {
		avatar: {
			user: defaultUserAvatar,
			team: defaultTeamAvatar,
		},
	},
	example: {
		achievement,
		gamfiLogo,
		removedSubmission,
		awardIcon,
		icon: {
			mission: iconMission,
			calendar,
			challenge: {
				text: textIcon,
				video: videoIcon,
				image: imageIcon,
				defaultIcon: challengeIcon,
				ranking: rankingIcon,
				mode: {
					team: teamIcon,
					user: userIcon,
					container: teamIcon,
				},
				participationMode: {
					user: individualMode,
					team: teamMode,
					container: teamMode,
				},
			},
		},
	},
	welcomePage: {
		welcomePage1,
	},
	icon: {
		gamfi,
		staticNotificationTypes: {
			default: staticDefaultNotification,
			email: staticEmailNotification,
			challengeSubmission: {
				new: newStatus,
				accepted: acceptedStatus,
				rejected: rejectedStatus,
			},
			declarationTask: {
				accepted: acceptedStatus,
				rejected: rejectedStatus,
			},
			bonusTransactionNotification: staticBonusTransactionNotification,
			awardedPlace: challengeIcon,
		},
		submissionType: {
			gif: submissionTypeGif,
			image: submissionTypeImage,
			imageWhite: submissionTypeImageWhite,
			image2: submissionTypeImage2,
			text: submissionTypeText,
			link: submissionTypeLink,
			video: submissionTypeVideo,
			videoWhite: submissionTypeVideoWhite,
			video2: submissionTypeVideo2,
		},
		orderTaskIcon,
	},
	currency: {
		bucks,
		bucksBig,
		exp,
		expBig,
		diamond,
		diamondBig,
		action,
		actionBig,
	},
	portal: {
		alarm: MdAlarm,
		error: MdError,
		phone: FiSmartphone,
		email: FiMail,
		expandMore: FiChevronDown,
		expandLess: FiChevronUp,
		users,
		check: FiCheck,
		filter: FiFilter,
		sectionIcons,
		knowledgeBaseIcon: FiChevronRight,
	},
	battles: {
		battlesLogo,
		battlesIcon,
		battlesHallOfFame,
		loadMore: FiChevronDown,
		warning,
	},
};
