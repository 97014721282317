import React, { useEffect } from 'react';
import { BattlesListItemProps } from './BattlesListItemType';
import { FormattedMessage } from 'react-intl';
import images from '../../../../../assets/images';
import { getTimeDiff } from '../../../helper/getTimeDiff';
import { Button } from '../../../../_/common/button/Button';
import BattlesService, { AcceptBattleAction } from 'services/battles/BattlesService';
import { wire } from 'react-hot-wire';
import { useMutation } from 'react-query';
import { BattleStatus } from 'models/battles/Battle';
import { BattleListItemPlayers } from './common/Players';

export type BattlesListItemWaitingProps = BattlesListItemProps;
export type BattlesListItemWaitingWiredProps = BattlesListItemWaitingProps & {
	'services.battlesService': BattlesService;
};

export const BattlesListItemWaiting = ({
	'services.battlesService': battlesService,
	...props
}: BattlesListItemWaitingWiredProps) => {
	const acceptBattle = useMutation((action: AcceptBattleAction) =>
		battlesService.acceptBattle(props.battle.id, action)
	);
	const isChallenger = props.battle.challengerId === props.currentPlayer?.userId;
	const canDoAction = props.battle.status === BattleStatus.WAITING && !!props.battle.challengerId;
	const isLoading = acceptBattle.isLoading || acceptBattle.isSuccess;

	useEffect(() => {
		if (acceptBattle.isSuccess) {
			props.refetch();
		}
	}, [acceptBattle.isSuccess]);

	if (!canDoAction) {
		return (
			<div className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
				<div className="tile battlesList__tile fs-4 rounded h-100 p-3 d-flex flex-column">
					<div className="font-weight-bold mx-auto mb-3 text-center">{props.battle.name}</div>
					<BattleListItemPlayers {...props} />

					<div className="text-center tile__completed">
						<p className="tile__color--gray mb-1">
							<FormattedMessage id={`battle-list.status.waiting.startAt`} />
						</p>
						<p className="font-weight-bold">
							{props.battle.startDate.format('L')}
							{', '}
							{props.battle.startDate.format('LT')}
						</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="col-12 col-md-8 col-lg-6  mb-4 mb-sm-5">
			<div className="tile battlesList__tile fs-4 rounded h-100 p-3 d-flex flex-row">
				<div>
					{!!props.opponent && (
						<div
							className="tile__image rounded-circle background-image size--cover"
							style={{
								backgroundImage: `url(${props.opponent.avatar || images.default.avatar.user})`,
							}}
							title={`${props.opponent.firstname} ${props.opponent.surname}`}
						/>
					)}
				</div>
				<div className="ml-3 tile__color--gray">
					<div className="font-weight-bold mx-auto mb-1 tile__color--main">{props.battle.name}</div>
					<div className="mb-1">
						<FormattedMessage
							id={`battle-list.status.waiting.${isChallenger ? 'challenger' : 'challenged'}`}
							values={{
								name: (
									<span className="font-weight-bold tile__color--main">
										{props.opponent?.firstname} {props.opponent?.surname}
									</span>
								),
							}}
						/>
					</div>
					{isChallenger && (
						<div className="mb-1">
							<FormattedMessage
								id={`battle-list.status.waiting.awaiting`}
								values={{
									time: (
										<span className="font-weight-bold tile__color--main">
											{props.battle.startDate.fromNow(true)}
										</span>
									),
								}}
							/>
						</div>
					)}
					<div className="mb-2">
						<FormattedMessage
							id={`battle-list.status.waiting.start`}
							values={{
								time: (
									<span className="font-weight-bold tile__color--main">
										{props.battle.startDate.format('L')}
										{', '}
										{props.battle.startDate.format('LT')}
									</span>
								),
								duration: (
									<span className="font-weight-bold tile__color--main">
										{getTimeDiff(props.battle.startDate, props.battle.endDate)}
									</span>
								),
							}}
						/>
					</div>

					<div>
						{isChallenger ? (
							<Button
								color="interactive"
								isLoading={isLoading}
								onClick={() => !isLoading && acceptBattle.mutate('cancel')}
							>
								<FormattedMessage id="battle-list.status.waiting.cancel" />
							</Button>
						) : (
							<>
								<Button
									color="interactive"
									isLoading={isLoading}
									onClick={() => !isLoading && acceptBattle.mutate('accept')}
									className="mr-1"
								>
									<FormattedMessage id="battle-list.status.waiting.accept" />
								</Button>
								<Button
									color="secondary"
									isLoading={isLoading}
									onClick={() => !isLoading && acceptBattle.mutate('reject')}
								>
									<FormattedMessage id="battle-list.status.waiting.reject" />
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default wire<BattlesListItemWaitingWiredProps, BattlesListItemWaitingProps>(
	['services.battlesService'],
	BattlesListItemWaiting
);
