import React, { useState } from 'react';
import BattlesListItem from './item/BattlesListItem';
import Loader from 'components/commons/_/loader/Loader';
import images from 'assets/images';
import BattleDetailsModal from './details/BattleDetailsModal';
import { BattleListEmpty } from './item/BattleListEmpty';
import { UseGetBattleListQueryReturn } from '../hook/api/useGetBattleList';
import DuelsToAcceptModal from './modal/DuelsToAcceptModal';

export type BattlesListProps = {
	tab: string;
	myBattleListQuery: UseGetBattleListQueryReturn;
};

export const BattlesList = ({ tab, myBattleListQuery }: BattlesListProps) => {
	const LoadMoreIcon = images.battles.loadMore;
	const [selectedBattleId, setSelectedBattleId] = useState<string | undefined>(undefined);

	return (
		<>
			<DuelsToAcceptModal myBattleListQuery={myBattleListQuery} />
			{myBattleListQuery.isFetching && !myBattleListQuery.isFetchingNextPage ? (
				<div className="d-flex justify--center w-100 mt-4 mt-sm-5">
					<Loader fixed={false} />
				</div>
			) : !myBattleListQuery.isFetching && !myBattleListQuery.data?.pages[0]?.items.length ? (
				<div className="battlesList h-100 w-100 mt-3">
					<BattleListEmpty type={tab} />
				</div>
			) : (
				<div className="battlesList row mt-4 mt-sm-5">
					<BattleDetailsModal battleId={selectedBattleId} onClose={() => setSelectedBattleId(undefined)} />
					{myBattleListQuery.data?.pages.map((page, i) => (
						<React.Fragment key={i}>
							{page.items.map(battle => (
								<BattlesListItem
									key={battle.id}
									battle={battle}
									openBattle={setSelectedBattleId}
									refetch={myBattleListQuery.refetch}
								/>
							))}
						</React.Fragment>
					))}
				</div>
			)}
			<div className="d-flex justify--center">
				{!myBattleListQuery.isLoading &&
					myBattleListQuery.hasNextPage &&
					(myBattleListQuery.isFetchingNextPage ? (
						<Loader fixed={false} />
					) : (
						<LoadMoreIcon
							className="battlesList__load-more-icon"
							onClick={() => myBattleListQuery.fetchNextPage()}
						/>
					))}
			</div>
		</>
	);
};

export default BattlesList;
