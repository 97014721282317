import { BattlesListItemProps } from '../BattlesListItemType';
import images from 'assets/images';
import React from 'react';
import classnames from 'classnames';

export type BattleListItemPlayersProps = BattlesListItemProps & { showScore?: boolean };

export const BattleListItemPlayers = ({ showScore, currentPlayer, opponent }: BattleListItemPlayersProps) => {
	return (
		<div className="d-flex flex-row justify--between pb-3">
			{!!currentPlayer && (
				<div className="position-relative tile__player">
					<div
						className="tile__image rounded-circle background-image size--cover"
						style={{
							backgroundImage: `url(${currentPlayer.avatar || images.default.avatar.user})`,
						}}
						title={`${currentPlayer.firstname} ${currentPlayer.surname}`}
					/>
					{!!showScore && (
						<div
							className={classnames('tile__score tile__score--left font-weight-bold fs-3 fs-md-4', {
								'tile__score--winner': currentPlayer.score > (opponent?.score || 0),
							})}
						>
							{currentPlayer.originalScore === null ? '-' : currentPlayer.score}
						</div>
					)}
				</div>
			)}
			<div className="align-self-end tile__color--gray">vs</div>
			{!!opponent && (
				<div className="position-relative tile__player">
					<div
						className="tile__image rounded-circle background-image size--cover"
						style={{
							backgroundImage: `url(${opponent.avatar || images.default.avatar.user})`,
						}}
						title={`${opponent.firstname} ${opponent.surname}`}
					/>
					{!!showScore && (
						<div
							className={classnames('tile__score tile__score--right font-weight-bold fs-3 fs-md-4', {
								'tile__score--winner': opponent.score > (currentPlayer?.score || 0),
							})}
						>
							{opponent.originalScore === null ? '-' : opponent.score}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
