import React from 'react';
import { BattlesListItemProps } from './BattlesListItemType';
import { BattleListItemPlayers } from './common/Players';
import { FormattedMessage } from 'react-intl';

export type BattlesListItemCanceledProps = BattlesListItemProps;

export const BattlesListItemCanceled = (props: BattlesListItemCanceledProps) => {
	return (
		<div className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
			<div className="tile battlesList__tile fs-4 rounded h-100 p-3 d-flex flex-column">
				<div className="font-weight-bold mx-auto mb-3 text-center">{props.battle.name}</div>
				<BattleListItemPlayers {...props} />
				<div className="text-center tile__canceled">
					<p className="tile__color--gray mb-1">
						<FormattedMessage id={`battle-list.status.canceled.title`} />
					</p>
				</div>
			</div>
		</div>
	);
};

export default BattlesListItemCanceled;
