import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import images from 'assets/images';
import Wallet from './wallet/Wallet';
import { FormattedMessage } from 'react-intl';
import NewDuelModal from './newDuel/NewDuelModal';

export default ({ user, level }) => {
	const showLevelProgress = useMemo(() => !!level.next || level.progress.percentage, [
		level.next,
		level.progress.percentage,
	]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<NewDuelModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
			<div
				className={classnames(
					'user-panel-battles position-fixed d-flex align-items-center justify-content-center'
				)}
			>
				<div className="d-flex align-items-center user-panel-battles__userContainer">
					<div
						className={classnames('user-panel-battles__avatarContainer position-relative', {
							'user-panel-battles__avatarContainer--progress': showLevelProgress,
						})}
					>
						{showLevelProgress ? (
							<div className="position-absolute">
								<CircularProgressbar
									value={level.progress.percentage}
									styles={buildStyles({
										strokeLinecap: 'butt',
									})}
								/>
							</div>
						) : null}
						<div className="position-absolute w-100 h-100 d-flex align-items-center">
							{user.userProfile ? (
								<NavLink to={`/user/${user.userProfile.id()}/details`} className="mx-auto">
									<div
										className="user-panel-battles__avatar rounded-circle"
										style={{
											backgroundImage: `url(${user.userProfile.avatar() ||
												images.default.avatar.user})`,
										}}
									/>
								</NavLink>
							) : (
								<NavLink to="/user/details" className="mx-auto">
									<div
										className="user-panel-battles__avatar rounded-circle"
										style={{
											backgroundImage: `url(${images.default.avatar.user})`,
										}}
									/>
								</NavLink>
							)}
						</div>
					</div>
					{level.current && (
						<div className="user-panel-battles__level rounded-circle d-flex align-items-center justify--center fs-5 fs-sm-7 font-weight-bold">
							{level.current.levelNumber()}
						</div>
					)}
					{user.wallet && (
						<div className="user-panel-battles__wallet">
							<Wallet wallet={user.wallet} key={Math.random()} />
						</div>
					)}
				</div>
				<div className="user-panel-battles__menu d-flex">
					<Link to="/battles/ongoing" className="d-flex flex-column">
						<div className="rounded-circle user-panel-battles__menu__icon user-panel-battles__menu__icon--background">
							<img src={images.battles.battlesIcon} alt="battles" />
						</div>

						<div className="font-weight-bold">
							<FormattedMessage id="battle-list.title" />
						</div>
					</Link>
					<div
						className="d-flex flex-column position-relative justify-content-end user-panel-battles__menu__main"
						onClick={() => setIsModalOpen(true)}
					>
						<div className="user-panel-battles__menu__main-icon">
							<img src={images.battles.battlesLogo} alt="logo" />
						</div>
						<div className="font-weight-bold text-center">
							<FormattedMessage id="battles.new-game" />
						</div>
					</div>
					<div className="d-flex flex-column user-panel-battles__menu--disabled">
						<div className="user-panel-battles__menu__icon">
							<img src={images.battles.battlesHallOfFame} alt="hall of fame" />
						</div>
						<div className="font-weight-bold">
							<FormattedMessage id="battles.hof" />
						</div>
					</div>
				</div>
				<div></div>
			</div>
		</>
	);
};
