import EmailIntegration from 'models/application/integration/EmailIntegration';

export default class EmailIntegrationService {
	constructor(APIService, navigationService, accessTokenService, i18nService) {
		this.APIService = APIService;
		this._navigationService = navigationService;
		this._accessTokenService = accessTokenService;
		this._i18nService = i18nService;
	}

	toModel(integration) {
		return new EmailIntegration(integration);
	}

	login({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/hybrid/login`, data, undefined, { withoutToken: true }).then(
			({ admin, application }) => {
				if (!admin.error && admin.token) {
					this._accessTokenService.setAccessToken('admin', admin.token);
				}

				if (!application.error && application.token) {
					this._accessTokenService.setAccessToken('application', application.token);
				}

				this._navigationService.load('/');
			}
		);
	}

	check({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/check`, data, undefined, { withoutToken: true }).then(
			response => {
				return Promise[
					['user-not-verified', 'user-not-exists', 'user-inactive', 'user-profile-not-active'].includes(
						response
					)
						? 'reject'
						: 'resolve'
				](response);
			}
		);
	}

	registerCheck({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/register/check`, data);
	}

	registerComplete({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/register/complete`, data).then(accessToken => {
			this._accessTokenService.setAccessToken('application', accessToken);
			this._navigationService.load('/');
		});
	}

	registerStart({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/register/start`, {
			...data,
			locale: this._i18nService.locale(),
		});
	}

	resetPasswordCheck({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/reset-password/check`, data);
	}

	resetPasswordComplete({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/reset-password/complete`, data).then(accessToken => {
			this._accessTokenService.setAccessToken('application', accessToken);
			this._navigationService.load('/');
		});
	}

	resetPasswordStart({ id, ...data }) {
		return this.APIService.post(`v1/auth/email/${id}/reset-password/start`, data).catch(({ error }) =>
			Promise.reject(error.messageId)
		);
	}
}
