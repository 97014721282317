import React from 'react';
import { BattlesListItemProps } from './BattlesListItemType';
import { BattleListItemPlayers } from './common/Players';
import { FormattedMessage } from 'react-intl';

export type BattlesListItemResolvedProps = BattlesListItemProps;

export const BattlesListItemResolved = (props: BattlesListItemResolvedProps) => {
	const { battle, currentPlayer, opponent } = props;

	return (
		<div className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
			<div className="tile battlesList__tile  fs-4 rounded h-100 p-3 d-flex flex-column">
				<div className="font-weight-bold mx-auto mb-3 text-center">{battle.name}</div>
				<BattleListItemPlayers {...props} showScore />

				{!!currentPlayer && !!opponent && (
					<>
						{currentPlayer.score > opponent.score ? (
							<div className="text-center tile__resolved tile__resolved--winner">
								<p className="tile__color--gray mb-1">
									<FormattedMessage id={`battle-list.status.victory.title`} />
								</p>
								<p className="font-weight-bold">
									<FormattedMessage id={`battle-list.status.victory.subtitle`} />
								</p>
							</div>
						) : (
							<div className="text-center tile__resolved">
								<p className="tile__color--gray mb-1">
									<FormattedMessage id={`battle-list.status.defeat.title`} />
								</p>
								<p className="font-weight-bold">
									<FormattedMessage id={`battle-list.status.defeat.subtitle`} />
								</p>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default BattlesListItemResolved;
