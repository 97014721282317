import { useInfiniteQuery } from 'react-query';
import { BattleStatus } from 'models/battles/Battle';
import BattlesService from 'services/battles/BattlesService';

export type UseGetBattleListProps = {
	'services.battlesService': BattlesService;
	statuses?: BattleStatus[];
};

const countPerPage = 18;
export const getBattleListQueryKey = 'battles-my-battle-list';

export type UseGetBattleListQueryReturn = ReturnType<typeof useGetBattleList>;

export const useGetBattleList = (props: UseGetBattleListProps) => {
	const battlesService = props['services.battlesService'];

	return useInfiniteQuery({
		queryKey: [getBattleListQueryKey, props.statuses],
		queryFn: ({ pageParam }) => battlesService.fetchBattleList(countPerPage, pageParam, props.statuses),
		getNextPageParam: (lastPage, pages) =>
			Math.ceil(lastPage.count / countPerPage) > pages.length ? pages.length + 1 : undefined,
		cacheTime: 0,
	});
};

export default useGetBattleList;
