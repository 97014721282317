import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CgMenuRight, CgChevronDown, CgClose } from 'react-icons/cg';

export default function(BaseWrapperMenu, MenuLink, i18nService, Image, applicationService) {
	return class WrapperMenu extends BaseWrapperMenu {
		render() {
			const termsAndConditions = applicationService.settings().termsAndConditions();
			const menu = applicationService.menu();
			const logo = applicationService
				.settings()
				.headMetadata()
				.logo();

			return (
				<>
					<section
						className={classnames('header', {
							transparent: applicationService
								.settings()
								.colorScheme()
								.isMenuTransparent(),
						})}
					>
						{menu._logo ? (
							<NavLink to={menu._logo.url()}>
								<Image src={menu._logo.mobileImage || menu._logo.image} alt="[logo]" className="logo" />
							</NavLink>
						) : null}
						<div className="menu-desktop">
							<div className="positions">
								<ul>
									{menu._desktopPositions.map(position => (
										<li key={position.title}>
											<MenuLink position={position} inHeader={true} />
										</li>
									))}
								</ul>
							</div>
							{i18nService.availableLocales().length > 1 ? (
								<>
									<div className="separator" />
									<div
										className={classnames('languages', {
											'more-not-exists': !!!menu._more,
										})}
										onClick={event => this.openMenu(event, 'language')}
										onTouchEnd={e => e.stopPropagation()}
									>
										{i18nService.locale().toUpperCase()} <CgChevronDown />
									</div>
								</>
							) : null}
							{menu._more ? (
								<>
									<div className="separator" />
									<div
										className="hamburger"
										onClick={event => this.openMenu(event, 'menu')}
										onTouchEnd={e => e.stopPropagation()}
									>
										<CgMenuRight />
									</div>
								</>
							) : null}
						</div>
					</section>
					{!!this.state.isOpen ? (
						<section
							className="menu-backdrop"
							onClick={event => this.closeMenu(event)}
							onTouchEnd={e => e.stopPropagation()}
						/>
					) : null}
					<section
						className={classnames('menu', {
							open: !!this.state.isOpen,
						})}
					>
						<div className="content">
							{this.state.currentSection === 'menu' ? (
								<>
									<div className="menu-title">
										<div className="title-text">
											<FormattedMessage id="header.title.menu" />
										</div>
										<div
											className="title-close"
											onClick={event => this.closeMenu(event)}
											onTouchEnd={e => e.stopPropagation()}
										>
											<CgClose />
										</div>
									</div>
									<hr />
									<ul onClick={event => this.closeMenu(event)} onTouchEnd={e => e.stopPropagation()}>
										{menu._desktopPositions.map(position => (
											<li className="desktop-position" key={position.title}>
												<MenuLink position={position} />
											</li>
										))}
										{menu._mobilePositions.map(position => (
											<li key={position.title}>
												<MenuLink position={position} />
											</li>
										))}
									</ul>
									<hr />
									<ul onClick={event => this.closeMenu(event)} onTouchEnd={e => e.stopPropagation()}>
										{termsAndConditions.termsAndConditions ? (
											<li>
												<a
													href={termsAndConditions.termsAndConditions}
													target="_blank"
													rel="noopener noreferrer"
													className="legal-link"
												>
													<FormattedMessage
														id={'terms-and-conditions.terms-and-conditions'}
													/>
												</a>
											</li>
										) : null}
										{termsAndConditions.privacyPolicy ? (
											<li>
												<a
													href={termsAndConditions.privacyPolicy}
													target="_blank"
													rel="noopener noreferrer"
													className="legal-link"
												>
													<FormattedMessage id={'terms-and-conditions.privacy-policy'} />
												</a>
											</li>
										) : null}
										{termsAndConditions.cookiePolicy ? (
											<li>
												<a
													href={termsAndConditions.cookiePolicy}
													target="_blank"
													rel="noopener noreferrer"
													className="legal-link"
												>
													<FormattedMessage id={'terms-and-conditions.cookie-policy'} />
												</a>
											</li>
										) : null}
									</ul>
								</>
							) : this.state.currentSection === 'language' ? (
								<>
									<div className="menu-title">
										<div className="title-text">
											<FormattedMessage id="header.title.language" />
										</div>
										<div
											className="title-close"
											onClick={event => this.closeMenu(event)}
											onTouchEnd={e => e.stopPropagation()}
										>
											<CgClose />
										</div>
									</div>
									<hr />
									<ul
										className="language"
										onClick={event => this.closeMenu(event)}
										onTouchEnd={e => e.stopPropagation()}
									>
										{i18nService.availableLocales().map(locale => (
											<li key={locale}>
												<a
													href="/"
													className={classnames({
														active: locale === i18nService.locale(),
													})}
													onClick={event => {
														event.preventDefault();
														i18nService.changeLocale(locale);
													}}
												>
													<FormattedMessage id={`locale.${locale}`} />
												</a>
											</li>
										))}
									</ul>
								</>
							) : null}
							{logo ? (
								<div className="menu-logo">
									<Image src={logo} />
								</div>
							) : null}
						</div>
					</section>
				</>
			);
		}
	};
}
