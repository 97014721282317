import * as Yup from 'yup';

export const schemaFactory = integration =>
	Yup.object().shape({
		forename: Yup.string().when([], {
			is: () => integration.checkForename,
			then: () => Yup.string().required('required'),
		}),
		surname: Yup.string().when([], {
			is: () => integration.checkSurname,
			then: () => Yup.string().required('required'),
		}),
		email: Yup.string()
			.email('invalid-email')
			.when([], {
				is: () => integration.checkEmail,
				then: () =>
					Yup.string()
						.required('required')
						.email('invalid-email'),
			}),
		reEmail: Yup.string().when(['email'], {
			is: email => !!email || integration.checkEmail,
			then: () =>
				Yup.string()
					.required('required')
					.test('is-same-as-email', 'emails-dont-match', (value, testContext) => {
						return value === testContext.parent.email;
					}),
		}),
		phone: Yup.string().when([], {
			is: () => integration.checkPhone,
			then: () => Yup.string().required('required'),
		}),
	});
