import React, { useState } from 'react';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';
import { BattlesListItemProps } from './BattlesListItemType';
import { BattleListItemPlayers } from './common/Players';
import { getTimeDiff } from '../../../helper/getTimeDiff';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useInterval } from 'usehooks-ts';

export type BattlesListItemOngoingProps = BattlesListItemProps;

export const BattlesListItemOngoing = (props: BattlesListItemOngoingProps) => {
	const { battle, openBattle } = props;
	const [, setKey] = useState(Math.random());

	useInterval(() => {
		setKey(Math.random);
	}, 1000);

	return (
		<div className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
			<div
				className="tile battlesList__tile  battlesList__tile--clickable fs-4 rounded h-100 p-3 d-flex flex-column"
				onClick={() => openBattle(battle.id)}
			>
				<div className="font-weight-bold mx-auto mb-3 text-center">{battle.name}</div>
				<BattleListItemPlayers {...props} showScore />
				{battle.endDate.toDate() > new Date() ? (
					<div className="d-flex align-items-center">
						<ProgressBar
							progress={Math.round(
								((Date.now() - battle.startDate.toDate().getTime()) /
									(battle.endDate.toDate().getTime() - battle.startDate.toDate().getTime())) *
									100
							)}
							className="align-self-center flex-grow-1"
							border={0}
							innerPadding={0}
						/>

						<div className="ml-3 tile__color--gray">{getTimeDiff(moment(), battle.endDate)}</div>
					</div>
				) : (
					<div className="text-center tile__completed">
						<p className="tile__color--gray mb-1">
							<FormattedMessage id={`battle-list.status.finished.title`} />
						</p>
						<p className="font-weight-bold">
							{battle.resolveDate.format('L')}
							{', '}
							{battle.resolveDate.format('LT')}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default BattlesListItemOngoing;
