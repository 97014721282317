export default class OAuthIntegrationService {
	constructor(APIService, navigationService, accessTokenService) {
		this.APIService = APIService;
		this._navigationService = navigationService;
		this._accessTokenService = accessTokenService;
	}

	authorize({ id }) {
		return this.APIService.post(`v1/auth/oauth/${id}/authorize`, undefined, {
			withoutToken: true,
		}).then(({ url }) => this._navigationService.load(url));
	}

	authorizeCallback({ id, code, state }) {
		return this.APIService.get(
			`v1/auth/oauth/${id}/hybrid/authorize-callback`,
			{
				code,
				state,
			},
			undefined,
			{ withoutToken: true }
		)
			.then(({ admin, application }) => {
				if (!admin.error && admin.token) {
					this._accessTokenService.setAccessToken('admin', admin.token);
				}

				if (!application.error && application.token) {
					this._accessTokenService.setAccessToken('application', application.token);
				}

				this._navigationService.load('/');
			})
			.catch(({ error }) => Promise.reject(error.messageId));
	}
}
