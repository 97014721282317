import browserHistory from 'services/browserHistory';

import achievement from 'services/achievement';
import application from 'services/application';
import auth from 'services/auth';
import battles from 'services/battles';
import challenge from 'services/challenge';
import common from 'services/common';
import container from 'services/container';
import graph from 'services/graph';
import news from 'services/news';
import notification from 'services/notification';
import onboarding from 'services/onboarding';
import ranking from 'services/ranking';
import rewardCafeteria from 'services/reward-cafeteria';
import store from 'services/store';
import tag from 'services/tag';
import task from 'services/task';
import team from 'services/team';
import trackers from 'services/trackers';
import user from 'services/user';

export default {
	browserHistory: {
		factory: () => browserHistory,
	},
	...achievement,
	...application,
	...auth,
	...battles,
	...challenge,
	...common,
	...container,
	...graph,
	...news,
	...notification,
	...onboarding,
	...ranking,
	...rewardCafeteria,
	...store,
	...tag,
	...task,
	...team,
	...trackers,
	...user,
};
