import { BattleStatus } from 'models/battles/Battle';
import { BattlesListItemProps } from './BattlesListItemType';
import BattlesListItemOngoing from './BattleListItemOngoing';
import BattlesListItemWaiting from './BattleListItemWaiting';
import BattlesListItemResolved from './BattleListItemResolved';
import BattlesListItemCanceled from './BattleListItemCanceled';

export const battleListItemTypes: {
	[key in BattleStatus]: React.FunctionComponent<BattlesListItemProps>;
} = {
	[BattleStatus.WAITING]: BattlesListItemWaiting,
	[BattleStatus.ACCEPTED]: BattlesListItemWaiting,
	[BattleStatus.ONGOING]: BattlesListItemOngoing,
	[BattleStatus.RESOLVED]: BattlesListItemResolved,
	[BattleStatus.CANCELED]: BattlesListItemCanceled,
	[BattleStatus.DECLINED]: BattlesListItemCanceled,
};
