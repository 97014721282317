import { useQuery } from 'react-query';
import BattlesService from 'services/battles/BattlesService';

export type UseGetBattleProps = {
	'services.battlesService': BattlesService;
	battleId?: string;
};

export const useGetBattle = (props: UseGetBattleProps) => {
	const battlesService = props['services.battlesService'];
	return useQuery({
		queryKey: ['battles-battle-details', props.battleId],
		queryFn: () => battlesService.fetchBattle(props.battleId as string),
		enabled: !!props.battleId,
	});
};

export default useGetBattle;
