import moment, { Moment } from 'moment';

export const getTimeDiff = (startTime: Moment, endTime: Moment) => {
	let duration = moment.duration(endTime.diff(startTime));

	if (duration.asSeconds() > 60) {
		duration = duration.add(60, 's'); // Add seconds we are not displaying
		const hrs = Math.floor(duration.asHours());
		const min = duration.minutes();

		return `${hrs}h ${min}m`;
	}

	const sec = duration.seconds();

	return `${sec}s`;
};
