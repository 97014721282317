import React, { useMemo } from 'react';
import Battle from 'models/battles/Battle';
import { wire } from 'react-hot-wire';
import UserProfileService from 'services/user/UserProfileService';
import { battleListItemTypes } from './type';

export type BattlesListItemProps = {
	battle: Battle;
	openBattle: (battleId: string) => void;
	refetch: () => void;
};

export type BattlesListItemWiredProps = BattlesListItemProps & {
	'services.userProfileService': UserProfileService;
};

export const BattlesListItem = ({
	battle,
	openBattle,
	refetch,
	'services.userProfileService': userProfileService,
}: BattlesListItemWiredProps) => {
	const currentPlayer = battle.players.find(player => player.userId === userProfileService.user()?.userProfile.id());
	const opponent = battle.players.find(player => player.userId !== userProfileService.user()?.userProfile.id());
	const ItemComponent = useMemo(() => battleListItemTypes[battle.status], [battle.status]);

	return (
		<ItemComponent
			battle={battle}
			currentPlayer={currentPlayer}
			opponent={opponent}
			openBattle={openBattle}
			refetch={refetch}
		/>
	);
};

export default wire<BattlesListItemWiredProps, BattlesListItemProps>(['services.userProfileService'], BattlesListItem);
