import BattlesService from 'services/battles/BattlesService';
import { useMutation } from 'react-query';

export type UseStartNewDuelProps = {
	'services.battlesService': BattlesService;
};

export type UseStartNewDuelReturn = ReturnType<typeof useStartNewDuel>;

export const useStartNewDuel = (props: UseStartNewDuelProps) => {
	const battlesService = props['services.battlesService'];

	return useMutation(() => battlesService.startNewDuel());
};

export default useStartNewDuel;
