import { Component } from 'react';

export default function(applicationService, browserHistoryService, platformService) {
	return class WrapperMenu extends Component {
		/**
		 *         ↑ Vertical movement (scroll) (deltaY)
		 *         |
		 *    90°  |
		 *         |
		 *         |   Too steep (≥ 60°) → IGNORED (scroll)
		 *         |  /
		 *         | / 60° (Threshold)
		 *         |/
		 * --------+----------------→ Horizontal movement (swipe) (deltaX)
		 *     (start)     Allowed Swipe (< 60°)
		 */
		swipeAngle = 30;
		swipeIgnoreBelow = 10; // px
		swpieDistance = 50; // px

		constructor(...args) {
			super(...args);

			this.state = {
				isOpen: false, // false / true
				currentSection: null, // null | "menu" | "language"
				isSwiping: false,
				touchStartX: 0,
				touchEndX: 0,
				touchStartY: 0,
				touchEndY: 0,
			};
		}

		openMenu(event, name) {
			event.stopPropagation();

			this.setState({
				isOpen: true,
				currentSection: name,
			});
		}

		closeMenu(event) {
			event.stopPropagation();

			this.setState({
				isOpen: false,
			});
		}

		componentDidMount() {
			document.addEventListener('touchstart', this.handleTouchStart, { passive: true });
			document.addEventListener('touchmove', this.handleTouchMove, { passive: true });
			document.addEventListener('touchend', this.handleTouchEnd);
		}

		handleTouchStart = e => {
			this.setState({
				touchStartX: e.touches[0].clientX,
				touchEndX: e.touches[0].clientX,
				touchStartY: e.touches[0].clientY,
				touchEndY: e.touches[0].clientY,
				isSwiping: false,
			});
		};

		handleTouchMove = e => {
			const touchEndX = e.touches[0].clientX;
			const touchEndY = e.touches[0].clientY;
			const deltaX = Math.abs(touchEndX - this.state.touchStartX);
			const deltaY = Math.abs(touchEndY - this.state.touchStartY);

			const angle = Math.abs(Math.atan2(deltaY, deltaX) * (180 / Math.PI));

			this.setState({ touchEndX, touchEndY, swipeAngle: angle });

			if (deltaX > 10 && angle < this.swipeAngle) {
				this.setState({ isSwiping: true });
			}
		};

		handleTouchEnd = () => {
			const { isSwiping, isOpen, touchStartX, touchEndX, swipeAngle } = this.state;
			const swipeDistanceX = touchEndX - touchStartX;

			if (!isSwiping || swipeAngle >= this.swipeAngle) return;

			if (!isOpen && swipeDistanceX < -this.swpieDistance) {
				this.setState({ isOpen: true, currentSection: 'menu' });
			} else if (isOpen && swipeDistanceX > this.swpieDistance) {
				this.setState({ isOpen: false });
			}
		};

		componentWillUnmount() {
			document.removeEventListener('touchstart', this.handleTouchStart);
			document.removeEventListener('touchmove', this.handleTouchMove);
			document.removeEventListener('touchend', this.handleTouchEnd);
		}
	};
}
