import ImportedIdIntegration from 'models/application/integration/ImportedIdIntegration';

export default class ImportedIdIntegrationService {
	constructor(APIService, navigationService, accessTokenService, i18nService) {
		this.APIService = APIService;
		this._navigationService = navigationService;
		this._accessTokenService = accessTokenService;
		this._i18nService = i18nService;
	}

	toModel(integration) {
		return new ImportedIdIntegration(integration);
	}

	login({ id, ...data }) {
		return this.APIService.post(`v1/auth/imported-id/${id}/login`, data, undefined, { withoutToken: true }).then(
			accessToken => {
				this._accessTokenService.setAccessToken('application', accessToken);
				this._navigationService.load('/');
			}
		);
	}

	check({ id, ...data }) {
		return this.APIService.post(`v1/auth/imported-id/${id}/check`, data, undefined, { withoutToken: true }).then(
			response => {
				return Promise[
					['user-not-verified', 'user-not-exists', 'user-inactive', 'user-profile-not-active'].includes(
						response
					)
						? 'reject'
						: 'resolve'
				](response);
			}
		);
	}

	resetPasswordCheckEmail({ id, ...data }) {
		return this.APIService.post(`v1/auth/imported-id/${id}/reset-password/check/email`, data, undefined, {
			withoutToken: true,
		});
	}

	resetPasswordCompleteEmail({ id, ...data }) {
		return this.APIService.post(`v1/auth/imported-id/${id}/reset-password/complete/email`, data, undefined, {
			withoutToken: true,
		})
			.then(accessToken => {
				this._accessTokenService.setAccessToken('application', accessToken);
				this._navigationService.load('/');
			})
			.catch(({ error }) => Promise.reject(error.messageId));
	}

	resetPasswordCompletePhone({ id, ...data }) {
		return this.APIService.post(`v1/auth/imported-id/${id}/reset-password/complete/phone`, data, undefined, {
			withoutToken: true,
		})
			.then(accessToken => {
				this._accessTokenService.setAccessToken('application', accessToken);
				this._navigationService.load('/');
			})
			.catch(({ error }) => Promise.reject(error.messageId));
	}

	resetPasswordStart({ id, ...data }) {
		return this.APIService.post(`v1/auth/imported-id/${id}/reset-password/start`, data, undefined, {
			withoutToken: true,
		}).catch(({ error }) => Promise.reject(error.messageId));
	}

	verify({ id, ...data }) {
		return this.APIService.post(
			`v1/auth/imported-id/${id}/verify?_locale=${this._i18nService.locale()}`,
			data,
			undefined,
			{ withoutToken: true }
		);
	}
}
