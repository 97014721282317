import * as Yup from 'yup';

export const PhysicalSchema = Yup.object().shape({
	forename: Yup.string()
		.max(255)
		.required(),
	surname: Yup.string()
		.max(255)
		.required(),
	companyName: Yup.string()
		.max(255)
		.nullable()
		.notRequired(),
	streetName: Yup.string()
		.max(255)
		.required(),
	buildingNumber: Yup.string()
		.max(255)
		.required(),
	flatNumber: Yup.string()
		.max(255)
		.nullable()
		.notRequired(),
	postalCode: Yup.string()
		.min(5)
		.max(255)
		.required(),
	city: Yup.string()
		.max(255)
		.required(),
	phone: Yup.string()
		.mobilePhone()
		.required(),
	email: Yup.string()
		.max(255)
		.email()
		.required(),
	reEmail: Yup.string()
		.required()
		.test('is-same-s-email', { message: 'emails-dont-match' }, (value, testContext) => {
			return value === testContext.parent.email;
		}),
	note: Yup.string()
		.max(255)
		.nullable()
		.notRequired(),
});
