import React, { useCallback, useEffect, useState } from 'react';
import BattlesInfoModal from '../../common/InfoModal';
import { wire } from 'react-hot-wire';
import UserProfileService from 'services/user/UserProfileService';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Button } from '../../../_/common/button/Button';
import BrowserHistoryService from 'services/application/BrowserHistoryService';
import { useLocation } from 'react-router-dom';
import { UseGetBattleListQueryReturn } from '../../hook/api/useGetBattleList';

export type DuelsToAcceptModalProps = { myBattleListQuery: UseGetBattleListQueryReturn };
export type DuelsToAcceptModalWiredProps = DuelsToAcceptModalProps & {
	'services.userProfileService': UserProfileService;
	'services.browserHistoryService': BrowserHistoryService;
};

export const DuelsToAcceptModal = ({
	myBattleListQuery,
	'services.userProfileService': userProfileService,
	'services.browserHistoryService': browserHistoryService,
}: DuelsToAcceptModalWiredProps) => {
	const location = useLocation();
	const [isModalOpen, setModalOpen] = useState(false);
	const onButtonClick = useCallback(() => {
		setModalOpen(false);
		browserHistoryService.push('/battles/waiting');
	}, [browserHistoryService, location]);
	const lastPage = myBattleListQuery.data?.pages.at(-1);

	useEffect(() => {
		if (location.pathname === '/battles/ongoing' && !!lastPage?.to_accept) {
			setModalOpen(true);
		}
	}, [lastPage]);

	return (
		<BattlesInfoModal isOpen={isModalOpen}>
			<div className="w-100 d-flex flex-column justify-content-center">
				<div className="fs-5 font-weight-bold text-center mb-2">
					<FormattedMessage
						id={`battles.to-accept-modal.title`}
						values={{
							name: userProfileService.user()?.userProfile.forename(),
						}}
					/>
				</div>
				<div className="color--gray text-center">
					<FormattedHTMLMessage
						id={`battles.to-accept-modal.text`}
						values={{
							count: lastPage?.to_accept || 0,
						}}
					/>
				</div>

				<div className="d-flex justify-content-center">
					<Button color="interactive" onClick={() => onButtonClick()} className="mt-4">
						<FormattedMessage id={`battles.to-accept-modal.button`} />
					</Button>
				</div>
			</div>
		</BattlesInfoModal>
	);
};

export default wire<DuelsToAcceptModalWiredProps, DuelsToAcceptModalProps>(
	['services.userProfileService', 'services.browserHistoryService'],
	DuelsToAcceptModal
);
