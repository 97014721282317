import { includes } from 'lodash';

export default class PlatformService {
	constructor() {
		this._userAgent = window.navigator.userAgent;
	}

	isMobile() {
		return includes(['mobile', 'tablet'], this._deviceType());
	}

	isIOS() {
		return /iPhone|iPad|iPod|iOS/i.test(this._userAgent);
	}

	_deviceType() {
		if (/iPhone|Android.+Mobile|Windows Phone|BlackBerry|Opera Mini/i.test(this._userAgent)) {
			return "mobile";
		} else if (/iPad|Tablet|Android(?!.*Mobile)/i.test(this._userAgent)) {
			return "tablet";
		}

		return "desktop";
	}
}
