import { createPortal } from 'react-dom';
import classnames from 'classnames';
import React from 'react';
import images from '../../../assets/images';

export type BattlesInfoModalProps = { isOpen?: boolean; children?: React.ReactElement | string };

export const BattlesInfoModal = ({ isOpen, children }: BattlesInfoModalProps) => {
	return createPortal(
		<div className={classnames('battlesModal', { 'battlesModal--open': isOpen })}>
			<div className="battlesModal__container">
				<div className="w-100 d-flex justify-content-center">
					<div className="battlesModal__main-icon">
						<img src={images.battles.battlesLogo} alt="logo" />
					</div>
				</div>
				<div className="battlesModal__content w-100 fs-4">{isOpen && children}</div>
			</div>
		</div>,
		document.body
	);
};

export default BattlesInfoModal;
