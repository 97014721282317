import { createUseStyles } from 'react-jss';

export default ({ isUserPanelVisible }) => {
	return createUseStyles(theme => ({
		'@keyframes viewIntro': {
			'0%': { paddingTop: '1rem' },
			'100%': { paddingTop: '0.5rem' },
		},
		view: {
			animation: '$viewIntro 0.2s ease-in',
		},
		title: {
			'& h2': {
				color: theme.color.system['color--content--foreground-60'],
			},
			/*[`@media (max-width: ${theme.grid.sm}px)`]: {
				backgroundColor: theme.color.system['color--content--foreground-5'],
			},*/
		},
		tabs: {
			backgroundColor: 'rgba(250, 250, 250, 0.9)',
			boxShadow: `0 0 15px ${theme.color.system['color--content--foreground-10']}`,
		},
		contentContainer: {
			paddingBottom: isUserPanelVisible
				? '108px' //  $user-avatar-size + $user-panel-distance
				: '0', // pb-0
			[`@media (max-width: ${theme.grid.xs}px)`]: {
				paddingBottom: isUserPanelVisible
					? '150px' //  more padding needed for iphone
					: '4rem',
			},
		},
	}));
};
