import MenuLink from 'views/wrapper/MenuLink';
import WrapperMenu from 'views/wrapper/WrapperMenu';

export default {
	'components.MenuLink': {
		factory: MenuLink,
		deps: [{ $ref: 'components.Image' }, { $ref: 'services.accessPopupService' }],
	},
	'components.WrapperMenu': {
		factory: WrapperMenu,
		deps: [
			{ $ref: 'components.base.WrapperMenu' },
			{ $ref: 'components.MenuLink' },
			{ $ref: 'services.i18nService' },
			{ $ref: 'components.Image' },
			{ $ref: 'services.applicationService' },
		],
	},
};
