import React, { Fragment, PureComponent } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as fns from 'date-fns';

import { formBlocks as blocks } from 'components/task/types/form/formBlock';

import MultipleChoiceClosedQuestion from 'models/common/form/question/MultipleChoiceClosedQuestion';
import SingleChoiceClosedQuestion from 'models/common/form/question/SingleChoiceClosedQuestion';
import OpenQuestion from 'models/common/form/question/open/OpenQuestion';
import DropdownClosedQuestion from 'models/common/form/question/DropdownClosedQuestion';
import DeclarationQuestion from 'models/common/form/question/DeclarationQuestion';
import DateOpenQuestion from 'models/common/form/question/open/DateOpenQuestion';
import EmailOpenQuestion from 'models/common/form/question/open/EmailOpenQuestion';
import TextOpenQuestion from 'models/common/form/question/open/TextOpenQuestion';
import UrlOpenQuestion from 'models/common/form/question/open/UrlOpenQuestion';
import FileUploadFormBlock from 'models/common/form/FileUploadFormBlock';

export default function(Layout) {
	return class FormTask extends PureComponent {
		render() {
			const { task, goBack } = this.props;
			const formBlocks = task.form().formBlocks();
			const disabled = (task.state().hasStatus('completed') && task.viewWhenComplete()) || task.isSubmitted();

			return (
				<Formik
					initialValues={this._initialValues(formBlocks)}
					validationSchema={this._validationSchema(formBlocks)}
					onReset={() => task.reset()}
					onSubmit={(values, actions) =>
						this._submit(values, formBlocks).then(() => actions.setSubmitting(false))
					}
				>
					{({ handleReset, isSubmitting, values }) => (
						<Form className="form d-flex flex-grow-1">
							<Layout
								task={task}
								actions={{
									submit: {
										buttonText: 'task-details.common.button.confirm',
									},
									goBack,
									tryAgain: handleReset,
									isSubmitting,
								}}
							>
								{formBlocks.map(formBlock => {
									const FormBlock = blocks[formBlock.type()];

									return (
										<Fragment key={formBlock.id()}>
											<div className="mb-4">
												<FormBlock
													formBlock={formBlock}
													values={values}
													task={task}
													disabled={disabled}
												/>
											</div>
										</Fragment>
									);
								})}
							</Layout>
						</Form>
					)}
				</Formik>
			);
		}

		_initialValues(formBlocks) {
			const { task } = this.props;

			return formBlocks.reduce((result, formBlock) => {
				result[formBlock.id()] = {};

				if (formBlock instanceof SingleChoiceClosedQuestion) {
					result[formBlock.id()] = {
						id: null,
						custom: null,
					};
				} else if (formBlock instanceof MultipleChoiceClosedQuestion) {
					result[formBlock.id()] = {
						answers: [],
						custom: null,
					};
				} else if (formBlock instanceof DropdownClosedQuestion) {
					result[formBlock.id()] = {
						id: null,
					};
				} else if (formBlock instanceof DeclarationQuestion) {
					result[formBlock.id()] = false;
				} else if (formBlock instanceof DateOpenQuestion) {
					result[formBlock.id()] = null;
				} else if (formBlock instanceof EmailOpenQuestion) {
					result[formBlock.id()] = null;
				} else if (formBlock instanceof TextOpenQuestion) {
					result[formBlock.id()] = null;
				} else if (formBlock instanceof UrlOpenQuestion) {
					result[formBlock.id()] = null;
				} else if (formBlock instanceof FileUploadFormBlock) {
					const currentRepetitionState = task.state().currentRepetitionState();
					const fileUploads =
						currentRepetitionState && !currentRepetitionState.completedAt()
							? currentRepetitionState.formResponse().fileUploads()
							: [];

					result[formBlock.id()] = currentRepetitionState && fileUploads.length > 0 ? fileUploads : [{}];
				}

				return result;
			}, {});
		}

		_validationSchema(formBlocks) {
			return Yup.object().shape(
				formBlocks.reduce((result, formBlock) => {
					if (formBlock instanceof SingleChoiceClosedQuestion) {
						result[formBlock.id()] = Yup.object().shape({
							id: Yup.string()
								.nullable()
								.when((_, schema) => (formBlock.required() ? schema.required() : schema)),
							custom: Yup.string()
								.nullable()
								.when(['id'], {
									is: id => id === formBlock.customAnswer()?.id(),
									then: () => Yup.string().required(),
								}),
						});
					} else if (formBlock instanceof MultipleChoiceClosedQuestion) {
						result[formBlock.id()] = Yup.object().shape({
							answers: Yup.array()
								.of(Yup.string())
								.when((_, schema) => (formBlock.required() ? schema.min(1) : schema)),
							custom: Yup.string()
								.nullable()
								.when(['answers'], {
									is: answers => answers.includes(formBlock.customAnswer()?.id()),
									then: () => Yup.string().required(),
								}),
						});
					} else if (formBlock instanceof DropdownClosedQuestion) {
						result[formBlock.id()] = Yup.object().shape({
							id: Yup.string()
								.nullable()
								.when((_, schema) => (formBlock.required() ? schema.required() : schema)),
						});
					} else if (formBlock instanceof DeclarationQuestion) {
						result[formBlock.id()] = Yup.boolean().when((_, schema) =>
							formBlock.required()
								? schema.oneOf([true], { message: 'check-required' }).required()
								: schema
						);
					} else if (formBlock instanceof DateOpenQuestion) {
						result[formBlock.id()] = Yup.string()
							.nullable()
							.when((_, schema) => (formBlock.required() ? schema.required() : schema));
					} else if (formBlock instanceof EmailOpenQuestion) {
						result[formBlock.id()] = Yup.string()
							.email()
							.nullable()
							.when((_, schema) => (formBlock.required() ? schema.required() : schema));
					} else if (formBlock instanceof TextOpenQuestion) {
						result[formBlock.id()] = Yup.string()
							.min(formBlock.minLength())
							.max(formBlock.maxLength())
							.nullable()
							.when((_, schema) => (formBlock.required() ? schema.required() : schema));
					} else if (formBlock instanceof UrlOpenQuestion) {
						result[formBlock.id()] = Yup.string()
							.url()
							.nullable()
							.when((_, schema) => (formBlock.required() ? schema.required() : schema));
					} else if (formBlock instanceof FileUploadFormBlock) {
						result[formBlock.id()] = Yup.array()
							.of(
								Yup.object().shape({
									id: Yup.string().when((_, schema) =>
										formBlock.required() ? schema.required() : schema
									),
								})
							)
							.when((_, schema) => (formBlock.required() ? schema.min(1) : schema));
					}

					return result;
				}, {})
			);
		}

		_submit(values, formBlocks) {
			const questions = [];

			formBlocks.forEach(formBlock => {
				if (!values[formBlock.id()]) {
					return;
				}

				if (formBlock instanceof SingleChoiceClosedQuestion || formBlock instanceof DropdownClosedQuestion) {
					if (!values[formBlock.id()].id) {
						return;
					}

					questions.push({
						id: formBlock.id(),
						answers: [
							{
								id: values[formBlock.id()].id,
								custom_answer: values[formBlock.id()].custom,
							},
						],
					});
				} else if (formBlock instanceof MultipleChoiceClosedQuestion) {
					if (!values[formBlock.id()].answers.length) {
						return;
					}

					questions.push({
						id: formBlock.id(),
						answers: values[formBlock.id()].answers.map(id => ({
							id,
							custom_answer: values[formBlock.id()].custom,
						})),
					});
				} else if (formBlock instanceof DeclarationQuestion) {
					questions.push({
						id: formBlock.id(),
						declared: values[formBlock.id()],
					});
				} else if (formBlock instanceof DateOpenQuestion) {
					questions.push({
						id: formBlock.id(),
						open_answer: fns.format(values[formBlock.id()], formBlock.answerFormat()),
					});
				} else if (formBlock instanceof OpenQuestion) {
					questions.push({
						id: formBlock.id(),
						open_answer: values[formBlock.id()],
					});
				}
			});

			return this.props.process({
				questions,
				finish: true,
			});
		}
	};
}
