import React from 'react';
import images from 'assets/images';
import { FormattedMessage } from 'react-intl';
import SVGImage from 'components/commons/SVGImage';
import { BattleStatus } from '../../../../models/battles/Battle';

export type BattleListEmptyProps = { type?: string };

export const BattleListEmpty = ({ type = BattleStatus.ONGOING }: BattleListEmptyProps) => {
	return (
		<div className="w-100 h-100 mb-4 mb-sm-5 battlesList__empty-tile">
			<div className="tile battlesList__tile fs-4 rounded h-100 d-flex flex-column align-items-center justify--center">
				<div className="mb-5 position-relative">
					<img src={images.battles.battlesLogo} alt="logo" />
					<div className="position-absolute bottom-right">
						<SVGImage src={images.battles.warning} className="image image--contain" />
					</div>
				</div>
				<div className="fs-5 text-center">
					<FormattedMessage id={`battle-list.empty.title.${type}`} />
				</div>
			</div>
		</div>
	);
};
