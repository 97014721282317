export interface BattlePlayerDTO {
	user_id: string;
	imported_id: string;
	avatar: string | null;
	firstname: string;
	surname: string;
	score: number | null;
}

export default class BattlePlayer {
	private _userId: string;
	private _importedId: string;
	private _avatar: string | null;
	private _firstname: string;
	private _surname: string;
	private _score: number | null;

	constructor(battlePlayer: BattlePlayerDTO) {
		this._userId = battlePlayer.user_id;
		this._importedId = battlePlayer.imported_id;
		this._avatar = battlePlayer.avatar;
		this._firstname = battlePlayer.firstname;
		this._surname = battlePlayer.surname;
		this._score = battlePlayer.score;
	}

	get userId() {
		return this._userId;
	}

	get importedId() {
		return this._importedId;
	}

	get avatar() {
		return this._avatar;
	}

	get firstname() {
		return this._firstname;
	}

	get surname() {
		return this._surname;
	}

	get score() {
		return this._score || 0;
	}

	get originalScore() {
		return this._score;
	}
}
