import React, { useEffect, useState } from 'react';
import BattleDetailsModalContent from './BattleDetailsModalContent';
import { wire } from 'react-hot-wire';
import BattlesService from 'services/battles/BattlesService';
import useGetBattle from '../../hook/api/useGetBattle';
import Loader from 'components/commons/_/loader/Loader';
import classnames from 'classnames';
import { createPortal } from 'react-dom';

export type BattleDetailsModalProps = {
	battleId?: string;
	onClose?: () => void;
};

export type BattleDetailsModalWiredProps = BattleDetailsModalProps & {
	'services.battlesService': BattlesService;
};

export const BattleDetailsModal = ({ battleId: _battleId, ...props }: BattleDetailsModalWiredProps) => {
	const [battleId, setBattleId] = useState(_battleId);
	const [isOpen, setIsOpen] = useState(!!battleId);
	const getBattleQuery = useGetBattle({ ...props, battleId });

	useEffect(() => {
		if (!!_battleId && !isOpen) {
			setBattleId(_battleId);
			setIsOpen(true);
		} else if (!_battleId && isOpen) {
			setIsOpen(false);
		}
	}, [_battleId, battleId, isOpen]);

	return createPortal(
		<div className={classnames('battleDetails', { 'battleDetails--open': isOpen })}>
			<div className="rounded shadow" onClick={event => event.stopPropagation()}>
				<button
					type="button"
					className="btn btn-interactive btn-close p-0 battleDetails__close-button"
					onClick={props.onClose}
				/>
			</div>
			<div className="battleDetails__container">
				{getBattleQuery.isLoading && (
					<div className="d-flex justify--center">
						<Loader fixed={false} />
					</div>
				)}
				{!!getBattleQuery.data && <BattleDetailsModalContent battle={getBattleQuery.data} />}
			</div>
		</div>,
		document.body
	);
};

export default wire<BattleDetailsModalWiredProps, BattleDetailsModalProps>(
	['services.battlesService'],
	BattleDetailsModal
);
