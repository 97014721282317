import React, { useEffect, useMemo, useState } from 'react';
import BattlesList from './list/BattlesList';
import { FormattedMessage } from 'react-intl';
import View from 'components/_/common/view/list/ListView';
import { RouteComponentProps } from 'react-router';
import useGetBattleList from './hook/api/useGetBattleList';
import { BattleStatus } from '../../models/battles/Battle';
import { wire } from 'react-hot-wire';
import BattlesService from '../../services/battles/BattlesService';

export type BattlesViewProps = RouteComponentProps<{ tab: string }>;
export type BattlesViewWiredProps = BattlesViewProps & { 'services.battlesService': BattlesService };
export type ListCounts = { [key: string]: number | undefined; waiting?: number; ongoing?: number } | null;

const statusesConfig: { [key: string]: BattleStatus[] } = {
	ongoing: [BattleStatus.ONGOING],
	waiting: [BattleStatus.WAITING, BattleStatus.ACCEPTED],
	resolved: [BattleStatus.RESOLVED],
	canceled: [BattleStatus.CANCELED, BattleStatus.DECLINED],
};

export const BattlesView = (props: BattlesViewWiredProps) => {
	const { tab } = props.match.params;
	const [listCounts, setListCounts] = useState<ListCounts>(null);
	const statusesFilter = useMemo(() => {
		return statusesConfig[tab];
	}, [tab]);
	const myBattleListQuery = useGetBattleList({ ...props, statuses: statusesFilter });

	useEffect(() => {
		if (myBattleListQuery.data) {
			const lastPage = myBattleListQuery.data.pages.at(-1);

			if (lastPage) {
				setListCounts({
					waiting: lastPage.waiting,
					ongoing: lastPage.ongoing,
				});
			}
		}
	}, [myBattleListQuery.data]);

	return (
		<View
			name="battleList"
			i18nKey="battle-list"
			config={{
				search: false,
			}}
			tabs={{
				url: '/battles/${tab}', // eslint-disable-line no-template-curly-in-string
				list: ['ongoing', 'waiting', 'resolved', 'canceled'].map(tab => ({
					tab,
					badge: listCounts?.[tab],
					content: <FormattedMessage id={`battle-list.tabs.${tab}`} />,
				})),
			}}
		>
			<hr />
			<BattlesList tab={tab} myBattleListQuery={myBattleListQuery} />
		</View>
	);
};

export default wire<BattlesViewWiredProps, BattlesViewProps>(['services.battlesService'], BattlesView);
