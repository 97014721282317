import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';

import Info from '../../../../Info';
import { schemaFactory } from './Register.schema';
import Register from './Register.template';
import { map } from 'lodash';
import AuthorizeCode from '../../../../reset-password/integrations/imported-id/authorize-code/AuthorizeCode';

export class RegisterComponent extends PureComponent {
	render() {
		const { intl, integration, identifier } = this.props;

		return (
			<Formik
				initialValues={{
					forename: '',
					surname: '',
					email: '',
					reEmail: '',
					phone: '',
					agreements: [],
				}}
				validationSchema={schemaFactory(integration)}
				onSubmit={(...args) => this.verify(...args)}
				render={props => {
					if (props.status === 'email-sent') {
						return (
							<Info
								title={intl.formatMessage({
									id: 'auth.register.integration.imported-id.status.email-sent.title',
								})}
								message={intl.formatMessage({
									id: 'auth.register.integration.imported-id.status.email-sent.message',
								})}
							/>
						);
					}

					if (props.status === 'sms-sent') {
						return <AuthorizeCode integration={integration} identifier={identifier} />;
					}

					return (
						<Register
							{...props}
							{...this.props}
							agreements={this.props['services.applicationService'].settings().agreements()}
							ReactMarkdown={this.props['components.ReactMarkdown']}
						/>
					);
				}}
			/>
		);
	}

	verify(values, actions) {
		const { integration, identifier } = this.props;

		actions.setStatus(null);

		if (!values.email && !values.phone) {
			actions.setFieldError('email', 'empty');
			actions.setFieldError('phone', 'one-of-two');
			actions.setSubmitting(false);

			return;
		}

		this.props['services.importedIdIntegrationService']
			.verify({
				id: integration.id,
				identifier,
				...values,
				agreements: map(values.agreements, id => ({ id })),
			})
			.then(response => {
				if (response === 'email') {
					actions.setStatus('email-sent');
				} else if (response === 'sms') {
					actions.setStatus('sms-sent');
				}
			})
			.catch(({ error }) => {
				const { data } = error;

				if (data.fields) {
					const { email, phone } = data.fields;

					if (email) {
						actions.setFieldError('email', `email.${email[0]}`);
					}

					if (phone) {
						actions.setFieldError('phone', `phone.${phone[0]}`);
					}
				} else {
					actions.setStatus(error.messageId);
				}

				actions.setSubmitting(false);
			});
	}
}

export default injectIntl(
	wire(
		['services.importedIdIntegrationService', 'services.applicationService', 'components.ReactMarkdown'],
		RegisterComponent
	)
);
