import wrapperMenu from 'components/wrapper/wrapperMenu';
import wrapperMissionList from 'components/wrapper/wrapperMissionList';

export default {
	'components.base.WrapperMenu': {
		factory: wrapperMenu,
		deps: [
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.browserHistoryService' },
			{ $ref: 'services.platformService' },
		],
	},
	'components.WrapperMissionList': {
		factory: wrapperMissionList,
		deps: [{ $ref: 'services.taskService' }],
	},
};
