import APIService from 'services/APIService';
import Battle, { BattleDTO, BattleStatus } from '../../models/battles/Battle';

export type AcceptBattleAction = 'accept' | 'reject' | 'cancel';

export default class BattlesService {
	constructor(private readonly ApiService: APIService) {}

	fetchBattleList(limit: number, page: number = 1, statuses?: BattleStatus[]) {
		return this.ApiService.get<{
			items: BattleDTO[];
			count: number;
			waiting?: number;
			ongoing?: number;
			to_accept?: number;
		}>(
			`/teambooster/battles/my-battles`,
			{ limit, page, statuses: !!statuses?.length ? statuses.join(',') : undefined },
			undefined,
			{
				version: 'v2',
				controller: 'workflows',
			}
		).then(response => ({
			...response,
			items: response.items.map(battleDTO => this.toModel(battleDTO)),
		}));
	}

	fetchBattle(battleId: string): Promise<Battle> {
		return this.ApiService.get<BattleDTO>(`/teambooster/battles/battle/${battleId}`, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
		}).then(response => this.toModel(response));
	}

	toModel(battleDTO: BattleDTO): Battle {
		return new Battle(battleDTO);
	}

	acceptBattle(battleId: string, action: AcceptBattleAction) {
		return this.ApiService.post(`/teambooster/battles/battle/${battleId}/${action}`, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
		});
	}

	startNewDuel() {
		return this.ApiService.post(`/teambooster/battles/start`, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
			omitErrorHandlers: true,
		});
	}
}
