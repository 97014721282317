import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import ExternalAnchor from 'models/application/anchor/types/external/External';

export default function(Image, accessPopupService) {
	return class MenuLink extends PureComponent {
		render() {
			const { position, inHeader = false, ...rest } = this.props;

			const target = position.anchor.target;
			const url = position.anchor.url();

			const params = { ...rest };
			let AnchorTag = null;

			if (position.anchor instanceof ExternalAnchor) {
				params.href = url;
				params.target = target;
				AnchorTag = 'a';
			} else {
				params.to = url;
				AnchorTag = NavLink;
			}

			return (
				<AnchorTag
					{...params}
					className={classnames({
						'has-image': !!position.image,
					})}
					activeClassName="active"
				>
					{inHeader ? (
						<>
							{position.image ? (
								<>
									<Image src={position.image} alt={position.title} />
									<div className="position-tooltip">{position.title}</div>
								</>
							) : position.title ? (
								position.title
							) : (
								'[---]'
							)}
						</>
					) : (
						<>
							{position.image ? (
								<Image src={position.image} style={{ marginRight: position.title ? '10px' : 0 }} />
							) : null}
							{!position.image && !position.title ? '[---]' : position.title ? position.title : '[---]'}
						</>
					)}
				</AnchorTag>
			);
		}
	};
}
